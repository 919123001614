$primary: #224d05;

$Primary-Font: "Montserrat", sans-serif;
$Primary-Font-Color: black;
$Secondary-Font-Color: #224d05;
$Primary-Color: white;
$Secondary-Color: #224d05;
$Background-Color: white;
$Page-Max-Width: 1300px;
$HeroImage: url("../Images/hero3.jpg");

@import '../../node_modules/bootstrap/scss/bootstrap';